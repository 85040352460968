main {
	overflow: hidden;
	font-family: 'Montserrat', sans-serif;
}

.vid-bg {
	width: 100%;
	height: 100%;
	position: relative;
	left: 0;
	top: 0;
	z-index: 0;
	pointer-events: none;

	video {
		background-size: cover;
		width: 100%;
	}
}

.__react_component_tooltip.tol-custom {
	background-color: rgba(23, 30, 10, 0.85) !important;
	opacity: 1 !important;
	border: 1px solid #ca4a00 !important;
	border-radius: 0 !important;
	min-width: px(310);

	&::before,
	&::after {
		content: none;
	}
}

$headSpace: px(0);
$footSpace: px(0);
$edgeSpace: px(-20);
$sizeDesktop: px(8);
$backgroundColor: #2dbfed;

.ScrollbarsCustom {
	width: 100% !important;

	.ScrollbarsCustom-Content {
		display: block !important;
	}

	.ScrollbarsCustom-Track {
		background: none !important;
	}

	.ScrollbarsCustom-Thumb {
		background: $backgroundColor !important;
		width: $sizeDesktop !important;
		border-radius: 0 !important;
	}

	&.trackYVisible {
		.ScrollbarsCustom-Wrapper {
			right: 0 !important;
			margin: 0 !important;
		}

		.ScrollbarsCustom-TrackY {
			right: $edgeSpace !important;
			width: $sizeDesktop !important;
			height: calc(100% - #{$headSpace} - #{$footSpace}) !important;
			top: $headSpace !important;
			background: transparent !important;
			border-radius: 0 !important;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: px(-1);
				width: px(1);
				height: 100%;
				background: rgba($backgroundColor, 0.7);
			}
		}
	}

	&.trackXVisible {
		.ScrollbarsCustom-Wrapper {
			bottom: 0 !important;
		}

		.ScrollbarsCustom-TrackX {
			bottom: $edgeSpace !important;
			height: $sizeDesktop !important;
			width: calc(100% - #{$headSpace} - #{$footSpace}) !important;
			left: $headSpace !important;
		}
	}
}

.page-backround {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;

	&::before {
		content: '';
		position: absolute;
		top: px(20);
		left: 0;
		width: 100%;
		height: 98%;
		//background: url(https://cdn.vn.garenanow.com/web/fo4vn/Khoa/2022/T12/CUP/cup_noel.png) no-repeat;
		//background-size: 100% 100%;
		z-index: 1;
	}
}

.wrap-content {
	width: px(1280);
	position: relative;
	margin: 0 auto;
	z-index: 3;
}

.card-section {
	height: px(1080);
	padding-top: px(155);

	.left-content {
		display: inline-block;
		width: px(586);
		vertical-align: top;

		.page-title {
			margin-bottom: px(7);
		}

		.des {
			font-size: px(20);
			line-height: px(26);
			color: #000;
			font-family: Montserrat;
			font-weight: 550;
		}

		.btn-control {
			display: block;
			width: px(301);
			position: relative;
			margin: 0 auto;
			margin-bottom: px(15);
			cursor: pointer;
			z-index: 10;

			// &::before {
			// 	content: '';
			// 	position: absolute;
			// 	top: px(-12);
			// 	left: 50%;
			// 	transform: translateX(-50%);
			// 	margin-left: px(13);
			// 	width: px(250);
			// 	height: px(22);
			// 	background: url(/images/snow-btn.png) no-repeat;
			// 	background-size: 100% 100%;
			// 	z-index: 2;
			// }

			img {
				cursor: pointer;
			}

			.btn-hover {
				position: absolute;
				opacity: 0;
				top: px(-2);
				left: 0;
			}

			&:hover {
				.btn-hover {
					opacity: 1;
				}
			}
		}

		.box-gift {
			width: px(498+106);
			height: 21.5vw;
			background-image: url($img + 'images/box_gift.png');
			background-size: 100% 100%;
			//margin-left: px(40);
			position: relative;

			.x2_label {
				content: '';
				position: absolute;
				width: px(108);
				height: px(102);
				z-index: 100;
				top: -15%;
				left: 90%;
				background-image: url($img + 'images/x2_label.png');
				background-size: 100% 100%;
			}

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				padding-top: px(12);
				text-align: center;
				position: relative;
				z-index: 1;

				li {
					display: inline-block;
					width: px(106);
					height: px(106);
					border-radius: px(8);
					border: 2px solid #0e79f6;
					margin: px(0) px(5);
					background-color: #091631;
					position: relative;

					img {
						width: px(100);
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}

					&.is-claim {
						img {
							filter: grayscale(1);
						}

						&:after {
							content: '';
							position: absolute;
							width: px(94);
							height: px(49);
							background-image: url($img + 'images/is_claim.png');
							background-size: 100% 100%;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}
	}

	.right-content {
		width: px(642);
		display: inline-block;
		vertical-align: top;
		padding-top: px(13);
		margin-left: px(50);
		position: relative;

		.info {
			position: absolute;
			width: px(35);
			top: px(-35);
			right: px(18);
		}

		.box-stadium {
			width: px(642);
			height: px(818);
			background-size: 100% 100%;
			position: relative;

			// &::before {
			//   content: '';
			//   position: absolute;
			//   top: px(-12);
			//   left: 50%;
			//   transform: translateX(-50%);
			//   width: px(564);
			//   height: px(22);
			//   background: url(/images/snow-map.png) no-repeat;
			//   background-size: 100% 100%;
			//   z-index: 9;
			// }

			&.stadium-1 {
				background-image: url($img + 'images/stadium_1.png');
			}

			&.stadium-2 {
				background-image: url($img + 'images/stadium_1.png');
			}

			&.stadium-3 {
				background-image: url($img + 'images/stadium_1.png');
			}

			&.stadium-4 {
				background-image: url($img + 'images/stadium_1.png');
			}

			&.stadium-5 {
				background-image: url($img + 'images/stadium_5.png');
			}

			.box-header {
				position: relative;

				span {
					font-size: px(18);
					font-weight: 550;
				}

				ul {
					padding: 0;
					margin: 0;
					list-style: none;

					li {
						display: inline-block;
						font-size: px(18);

						&:first-child {
							width: px(237);
						}

						&:nth-child(2) {
							width: px(220);
							height: px(41);
							background-image: url($img + 'images/stamina.png');
							background-size: 100% 100%;
							line-height: px(41);
							padding-left: px(42);
						}

						&:nth-child(3) {
							width: px(148);
							height: px(41);
							background-image: url($img + 'images/cups.png');
							background-size: 100% 100%;
							margin-left: px(7);
							line-height: px(41);
							padding-left: px(42);
						}
					}
				}

				.button-next {
					position: absolute;
					right: px(30);
					bottom: px(-20);
					width: px(153);
					animation: tada 1.5s linear infinite;

					&.prevent-click {
						animation: none;
					}

					&:hover {
						animation: boxShake 4s infinite linear;
					}
				}
			}

			.matrix {
				position: absolute;
				left: px(33);
				top: px(95);

				ul {
					padding: 0;
					margin: 0;
					display: block;

					li {
						display: inline-block;
						width: px(111);
						height: px(111);
						position: relative;
						margin: px(2) px(2);
						vertical-align: top;

						a,
						span {
							display: block;
							position: relative;
							width: px(111);
							height: px(111);
						}

						&.active-step {
							&:after {
								content: '';
								position: absolute;
								width: px(112);
								height: px(112);
								background-image: url($img + 'images/grass_active.png');
								background-size: 100% 100%;
								top: 0;
								left: 0;
							}
						}

						.move-box {
							&:after {
								content: '';
								position: absolute;
								width: px(112);
								height: px(112);
								background-image: url($img + 'images/box_move.png');
								background-size: 100% 100%;
								top: 0;
								left: 0;
							}

							&:before {
								content: '';
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								background-size: 100% 100%;
								z-index: 2;
							}

							&.move-left {
								&:before {
									width: px(68);
									height: px(71);
									background-image: url($img + 'images/arro_l.png');
								}

								&:hover {
									&:before {
										animation: moveLeft infinite 0.4s linear;
									}
								}
							}

							&.move-right {
								&:before {
									width: px(68);
									height: px(71);
									background-image: url($img + 'images/arro_r.png');
								}

								&:hover {
									&:before {
										animation: moveRight infinite 0.4s linear;
									}
								}
							}

							&.move-up {
								&:before {
									width: px(71);
									height: px(68);
									background-image: url($img + 'images/arro_u.png');
								}

								&:hover {
									&:before {
										animation: moveUp infinite 0.4s linear;
									}
								}
							}

							&.move-down {
								&:before {
									width: px(71);
									height: px(68);
									background-image: url($img + 'images/arro_d.png');
								}

								&:hover {
									&:before {
										animation: moveDown infinite 0.4s linear;
									}
								}
							}
						}

						.question {
							width: px(64);
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}

						.mtx-cup {
							width: px(85);
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}

				.wrap-box-matrix {
					position: relative;
					display: inline-block;
				}
			}
		}
	}
}

.game-progress {
	width: px(440);
	padding: px(30);

	.mission {
		margin-bottom: px(15);

		.mission-txt {
			display: inline-block;
			vertical-align: middle;
			font-size: px(20);
			color: #000;
			width: px(210);
			font-weight: 400;
		}

		a {
			&:hover {
				transform: translate(0, px(-5));
			}
		}

		.mission-point {
			display: inline-block;
			vertical-align: middle;
			line-height: px(47);
			width: px(162);
			height: px(47);
			background-image: url($img + 'images/stamina_box.png');
			background-size: 100% 100%;
			font-size: px(16);
			font-weight: 700;
			text-transform: uppercase;
			color: #fff;
			text-align: left;

			&.is-done {
				pointer-events: none;
				filter: grayscale(1);
			}

			span {
				width: px(120);
				text-align: center;
			}

			.text-neon {
				span {
					&:before {
						-webkit-text-stroke: 4px #6b2b2e;
					}
				}
			}
		}
	}
}

.pop-body-history {
	width: px(870);
	padding: px(30) px(50);
}

.tbl-history {
	width: 100%;

	thead {
		tr {
			th {
				font-size: px(20);
				font-weight: 700;
				text-align: center;
				white-space: nowrap;
			}
		}
	}

	tbody {
		tr {
			td {
				padding: px(10) px(5);
				font-size: px(16);
				font-weight: 500;
				text-align: center;
			}
		}
	}
}

.chess-icon {
	position: absolute;
	display: block;
	width: 3.08333vw;
	margin-left: -0.6vw;
	z-index: 69;
	animation: boxBounce 4.5s both infinite;
	pointer-events: none;

	&.chess-jumping {
		transition: all 0.5s; // linear 0s;

		img {
			animation: chessJumping 0.6s linear !important;
		}
	}
}

@keyframes chessJumping {
	0%,
	100% {
		transform: translate(0, 0);
	}

	50% {
		transform: translate(0, -35px);
	}
}

.img-pop-gift {
	width: px(212);
	margin: 0 auto;
	margin-bottom: px(30);
}

.x2_label {
	content: '';
	position: absolute;
	width: px(108);
	height: px(102);
	z-index: 100;
	top: 22%;
	left: 60%;
	background-image: url($img + 'images/x2_label.png');
	background-size: 100% 100%;
}

.top-all-content {
	height: px(280) !important;
	width: 100% !important;

	.ScrollbarsCustom-Content {
		display: block !important;
	}
}

@keyframes heartBeat {
	0% {
		transform: scale(1);
	}

	14% {
		transform: scale(1.1);
	}

	28% {
		transform: scale(1);
	}

	42% {
		transform: scale(1.1);
	}

	70% {
		transform: scale(1);
	}
}

@keyframes boxBounce {
	0%,
	6.67%,
	17.67%,
	26.67%,
	33.33% {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}

	13.33%,
	14.33% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -30px, 0);
		transform: translate3d(0, -30px, 0);
	}

	23.33% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -15px, 0);
		transform: translate3d(0, -15px, 0);
	}

	30% {
		-webkit-transform: translate3d(0, -4px, 0);
		transform: translate3d(0, -4px, 0);
	}
}

@keyframes boxShake {
	0%,
	100% {
		transform: translateZ(0) scale(0.9);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-7px, 0, 0) scale(0.9);
	}

	20%,
	40%,
	60%,
	80% {
		transform: translate3d(7px, 0, 0) scale(0.9);
	}
}

@keyframes moveLeft {
	0%,
	50% {
		margin-left: px(0);
	}

	50% {
		margin-left: px(-10);
	}
}

@keyframes moveRight {
	0%,
	50% {
		margin-left: px(0);
	}

	50% {
		margin-left: px(10);
	}
}

@keyframes moveUp {
	0%,
	50% {
		margin-top: px(0);
	}

	50% {
		margin-top: px(-10);
	}
}

@keyframes moveDown {
	0%,
	50% {
		margin-top: px(0);
	}

	50% {
		margin-top: px(10);
	}
}

.custom-tool-white {
	opacity: 1 !important;
	background: transparent !important;

	&:before,
	&:after {
		content: none !important;
	}

	img {
		width: px(550);
	}
}

.open-gift-cup {
	animation: rotatePer 2s infinite linear;

	img {
		height: px(90);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.special-tool-tip {
	opacity: 1 !important;
	background: white !important;
	border: 2px solid #0e79f6;
	background-color: #091631;
	border-radius: px(8);
	font-weight: 500;

	div {
		width: px(450);
		min-height: px(400);
		background: inherit;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding-top: px(30);
		padding-bottom: px(10);

		h2 {
			color: #091631;
		}

		p {
			color: #091631;
		}

		ul {
			li {
				width: px(180);
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;

				img {
					opacity: 0.95;
					border: 2px solid #0e79f6;
					border-radius: px(8);
					background-color: #091631;
					width: px(140);
					height: px(140);
					margin-bottom: px(10);
				}

				span {
					text-align: center;
					display: block;
					width: px(160);
					color: #091631;
					font-size: px(15);
				}

				&.is-claim {
					img {
						filter: grayscale(1);
					}

					&:after {
						z-index: 1000;
						content: '';
						position: absolute;
						width: px(94 * 1.3);
						height: px(49 * 1.3);
						background-image: url($img + 'images/is_claim.png');
						background-size: 100% 100%;
						left: 50%;
						top: 35%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
}

.gifts {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	span {
		display: block;
		font-size: px(16);
		text-transform: uppercase;
		text-align: center;
	}

	&__item {
		width: 24%;

		&.active {
			.gifts__img {
				background-color: #40dff6;
				border: 2px solid #fff;
			}
		}
	}

	&__img {
		width: 100%;
		height: px(150);
		border: 2px solid #40dff6;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: px(10);

		img {
			width: auto;
			height: px(130) !important;
			object-fit: contain;
		}
	}
}

@keyframes won {
	0%,
	30%,
	60%,
	100% {
		opacity: 1;
	}
	15%,
	45%,
	75% {
		opacity: 0;
	}
}
