@keyframes drop-down {
  0% {
    top: -100%;
  }
  100% {
    top: -17.5rem;
  }
}

@keyframes routate {
  0% {
    -webkit-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  50% {
    -webkit-transform: rotate(-4deg);
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  100% {
    -webkit-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
  }
}

@keyframes dance {
  0% {
    transform: rotateY(0)
  }
  25% {
    transform: rotateY(25deg)
  }
  50% {
    transform: rotateY(0)
  }
  75% {
    transform: rotateY(-25deg)
  }
  100% {
    transform: rotateY(0)
  }
}

@keyframes swingLine {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes jelly {
  11.1%,
  from,
  to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
    transform: skewX(-.78125deg) skewY(-.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(.39063deg) skewY(.39063deg);
    transform: skewX(.39063deg) skewY(.39063deg);
  }
  88.8% {
    -webkit-transform: skewX(-.19531deg) skewY(-.19531deg);
    transform: skewX(-.19531deg) skewY(-.19531deg);
  }
}

@keyframes leafMove {
  0% {
    transform: translate(0px, 0px)
  }
  50% {
    transform: translate(5px, -5px)
  }
  100% {
    transform: translate(0px, 0px)
  }
}

@keyframes leafMove2 {
  0% {
    transform: translate(0px, 0px)
  }
  50% {
    transform: translate(7px, -7px)
  }
  100% {
    transform: translate(0px, 0px)
  }
}

@keyframes leafMove3 {
  0% {
    transform: translate(0px, 0px)
  }
  50% {
    transform: translate(3px, -3px)
  }
  100% {
    transform: translate(0px, 0px)
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes drop-down-lixi {
  0% {
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 100rem;
  }
}

.jelly {
  backface-visibility: visible;
  animation-name: jelly;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.swing-line {
  animation: swingLine;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
}

.dance {
  animation: dance 2.5s infinite both linear;
}

@keyframes falling {
  0% {
    margin-top: 0;
    margin-left: 0%;
    -webkit-transform: scale3d(1, .5, 1) rotateZ(30deg);
  }
  25% {
    margin-top: 100px;
    margin-left: 90%;
    -webkit-transform: scale3d(1, .3, 1) rotateZ(0deg);
  }
  50% {
    margin-top: 200px;
    margin-left: 10%;
    -webkit-transform: scale3d(1, .2, 1) rotateZ(0deg);
  }
  75% {
    margin-top: 300px;
    margin-left: 80%;
    opacity: 1;
    -webkit-transform: scale3d(1, .3, 1) rotateZ(0deg);
  }
  100% {
    margin-top: 400px;
    margin-left: 20%;
    opacity: 0;
    -webkit-transform: scale3d(1, .2, 1) rotateZ(0deg);
  }
}
@keyframes bouncing {
  20%,
  53%,
  80%,
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -10px, 0) scale3d(.95, 1.15, 1);
    transform: translate3d(0, -10px, 0) scale3d(.95, 1.15, 1);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes zoomFire {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  50%{
    transform: scale(0.9);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.8);
    opacity: 1;
  }
}