/// Typography

/// Headings
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 0.4em 0;
  padding: 0;
  line-height: 1.2;

  // anchors in headings should remain like headings, not anchors
  a {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
}
h1, .h1 {
  font-size: 3.5rem;
}
h2, .h2 {
  font-size: 3.0rem;
}
h3, .h3 {
  font-size: 2.6rem;
}
h4, .h4 {
  font-size: 2.0rem;
}
h5, .h5 {
  font-size: 1.8rem;
}
h6, .h6 {
  font-size: 1.6rem;
}
.h1,.h2,.h3,.h4,.h5,.h6 {
  display: block;
}


/// Text elements
strong, b, .bold {
  font-weight: bold;
}
em, dfn, i, .italic {
  font-style: italic;
}
u, ins, .underline {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
sub, .subscribt {
  font-size: small;
  text-transform: subscribe;
}
sup, .superscript {
  font-size: small;
  text-transform: superscript;
}
del, s, .strike {
  text-decoration: line-through;
}
small {
  font-size: small;
}
mark {

}
