header {
	width: 100%;
	z-index: 99;
	position: absolute;
	top: 0;
	left: 0;
	height: px(88);
	font-size: px(18);
	overflow: hidden;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	background-size: 100% 100%;
	background-image: url($img + 'images/header-bg.png');
	.menu {
		z-index: 3;
		padding: 0;
		margin: 0;
		list-style: none;
		height: px(88);
		@include clearfix();
		li {
			display: inline-block;
			vertical-align: top;
			> a,
			> span {
				color: #172340;
				text-transform: uppercase;
				display: inline-block;
				&.btn-spin-act {
					height: px(49);
					line-height: px(49);
					background-image: url($img + 'images/box_round.png');
					background-size: 100% 100%;
					padding: 0 px(30);
					margin-top: px(38);
					text-shadow: 1px 1px 0 #1a391a, 1px -1px 0 #1a391a, -1px 1px 0 #1a391a,
						-1px -1px 0 #1a391a, 1px 0px 0 #1a391a, 0px 1px 0 #1a391a,
						-1px 0px 0 #1a391a, 0px -1px 0 #1a391a;
					color: #fff;
					&:hover {
						background-image: url($img + 'images/box_round_hover.png');
					}
				}
			}
			> a {
				&:hover {
					color: #fff;
				}
			}
			&.logo {
				margin-right: px(50);
				padding-top: px(30);
				img {
					width: px(202);
				}
			}
			&.action-l-group {
				width: px(203);
				float: right;
				margin: 0;
				margin-top: px(38);
				text-align: center;
				a {
					color: #fbc02b !important;
					display: block;
					height: px(49);
					background-image: url($img + 'images/box_login.png');
					background-size: 100% 100%;
					line-height: px(49);
					text-shadow: 1px 1px 0 #1a391a, 1px -1px 0 #1a391a, -1px 1px 0 #1a391a,
						-1px -1px 0 #1a391a, 1px 0px 0 #1a391a, 0px 1px 0 #1a391a,
						-1px 0px 0 #1a391a, 0px -1px 0 #1a391a;
					&:hover {
						transform: scale(0.92);
					}
				}
			}
			&.user {
				margin-left: px(40);
				font-weight: 700;
				font-family: Roboto;
				> span {
					display: inline-block;
					margin-top: px(48);
					font-size: px(24);
					color: #fff;
					-webkit-text-stroke: 2px #1a391a;
					span {
						display: inline-block;
						vertical-align: top;
					}
					b {
						display: inline-block;
						vertical-align: top;
						width: px(190);
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}
			&:nth-child(2) {
				a {
					&.btn-spin-act {
						background-image: url($img + 'images/box_round1.png');
						background-size: 100% 100%;
						&:hover {
							background-image: url($img + 'images/box_round1_hover.png');
						}
					}
				}
			}
			&:nth-child(3) {
				a {
					&.btn-spin-act {
						background-image: url($img + 'images/box_round2.png');
						background-size: 100% 100%;
						&:hover {
							background-image: url($img + 'images/box_round2_hover.png');
						}
					}
				}
			}
		}
	}
}
