.btn-primary{
  font-size: 1.9rem;
  padding: 0.8rem 4.4rem;
  color: #121212;
  border-radius: 7px;
  background: $primary-color;
  background: -moz-linear-gradient(top, #b99562 0%, #734e19 100%);
  background: -webkit-linear-gradient(top, #b99562 0%, #734e19 100%);
  background: linear-gradient(to bottom, #b99562 0%, #734e19 100%);
  outline: 0 !important;
  box-shadow: none !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b99562', endColorstr='#734e19', GradientType=0);
  &:hover{
    
  }
}