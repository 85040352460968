footer {
  height: px(200);

  img {
    width: 100%;
    @include device-1() {
      height: px(200);
    }
  }
}
