@keyframes ballRun1 {
    0% {
        transform: rotate(0) scale(1);
    }
    80%{
        opacity: 1;
    }
    95%{
        transform: rotate(45deg) scale(0.45);
    }
    100% {
        transform: rotate(45deg) scale(0.25);
        opacity: 0;
    }
}
@keyframes ballxoay {
   from{
    transform: rotate(0);
   }
   to{
    transform: rotate(3turn);
   }
}
@keyframes ballXoay1 {
    0% {
        transform: rotate(0);
    }
    95%{
        transform: rotate(-45deg);
    }
    100% {
        transform: rotate(-45deg);
    }
}
@keyframes ballRun3 {
    0% {
        transform: rotate(0) scale(1);
    }
    80%{
        opacity: 1;
    }
    95%{
        transform: rotate(-45deg) scale(0.3);
    }
    100% {
        transform: rotate(-45deg) scale(0.15);
        opacity: 0;
    }
}
@keyframes ballRun4 {
    0% {
        transform: rotate(0) scale(1);
    }
    50%{
        opacity: 1;
        transform: rotate(-45deg) scale(0.3);
    }
    100%{
        transform: translate(px(300),px(0));
        opacity: 0.3;
    }
}
@keyframes ballRun2 {
    0%{
        transform: translate(0,0);
    }
    50%{
       transform: translate(px(800),px(-50)) scale(0.7);
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
        transform: translate(px(-50),px(-300)) scale(0.3);
        opacity: 0.3;
    }
}
@keyframes ballRun5 {
    0%{
        transform: translate(0,0);
    }
    25%{
       transform: translate(px(800),px(-50)) scale(0.7);
    }
    50%{
        transform: translate(px(90),px(-300)) scale(0.5);
    }
    90%{
        opacity: 1;
    }
    100%{
        transform: translate(px(-300),px(-200)) scale(0.4);
        opacity: 0.3;
    }
}
@keyframes goalKepperLeft{
    0%{
        transform: translate(0,0);
    }
    90%{
        opacity: 1;
    }
    100%{
        transform: translate(px(-305),px(0));
        opacity: 0;
    }
}
@keyframes goalKepperRight{
    0%{
        transform: translate(0,0);
    }
    90%{
        opacity: 1;
    }
    100%{
        transform: translate(px(-75),px(0));
        opacity: 0;
    }
}
@keyframes wallJumping{
    0%,100%{
        transform: translate(0,0);
    }
    50%{
        transform: translate(0,px(-80));
    }
}