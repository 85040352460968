.widht-pop {
  z-index: 1050;
  width: auto;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  border-radius: px(10);
}

.content-border {
  background-color: transparent;
  border: none;
  padding: 0;
}

.pop-title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: px(28);
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-align: center;
  height: px(53);
  line-height: px(53);
  background-color: #40dff6;
}

.modal {
  text-align: center;

  .close-pop {
    position: absolute;
    right: px(-15);
    top: px(-30);
    z-index: 1050;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: px(40);

    &:hover {
      opacity: 0.75;
    }
  }

  .modal-dialog {
    @extend .widht-pop;

    .modal-content {
      @extend .content-border;
    }

    .modal-block {
      background-color: #fff;
    }

    .modal-body {
      padding: 0;
    }

    .modal-header {
      .close {
        position: absolute;
        right: 10px;
        font-weight: 200;
        font-size: 4rem;
        top: 0px;
        color: #9b9b9b;
      }
    }

    .md-header {
      @extend .pop-title;
    }
  }

  .pop-body {
    img {
      max-height: 650px;
    }
  }

  &.show {
    display: block;

    .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.6);
      opacity: 1;
    }
  }

  &.modal-img {
    text-align: center;

    .modal-dialog {
      .modal-content {
        padding: 0;
      }
    }
  }

  &.pop-history {
    .modal-dialog {
      .modal-block {
        background-image: url($img + 'images/pop-bg-2.png');
        background-size: 100% 100%;
        min-height: px(500);
      }
    }
  }

  &.pop-no-padd {
    padding-top: 40px;

    .modal-dialog {
      padding: 0;
    }

    .modal-block {
      padding: 0;

      .modal-content {
        padding: 0;
      }
    }
  }
}

//custom class swall
.ex-sw-title {
  margin: 0;
}

.ex-sw-content {
  margin-top: px(-50);
  padding: px(80) px(40);
  padding-bottom: px(10);
}

//--end
.swal2-popup.custom-modal {
  @extend .widht-pop;
  background-color: #fff;
  padding: 0;
  min-width: px(461);
  padding-bottom: px(30);

  .swal2-header {
    position: relative;
    z-index: 9;

    .swal2-title {
      @extend .pop-title;
      @extend .ex-sw-title;
      display: block !important;
      width: 100%;
    }
  }

  .swal2-actions {
    display: none !important;
  }

  .swal2-content {
    @extend .content-border;
    @extend .ex-sw-content;
    font-family: 'Montserrat', sans-serif;
    font-size: px(30);
    color: #000;
    font-weight: 500;
  }

  .pop-content {
    font-family: 'Montserrat', sans-serif;
    font-size: px(24);
    color: #000;
    font-weight: 500;
  }

  &.has-btn {
    .swal2-actions {
      display: block !important;
      font-size: px(20);
    }
  }

  .swal2-close {
    text-indent: -9999px;
    display: inline-block;
    width: 91px;
    height: 72px;
    position: absolute;
    background-image: url('/images/close-pop.png');
    background-size: cover;
    background-repeat: no-repeat;
    right: 0;
    z-index: 2;

    &:hover {
      opacity: 0.75;
      transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
    }
  }
}

.pop-gift-cd {
  width: px(600);
}

.pop-hd {
  width: px(600);
}

.text-neon {
  span {
    display: inline-block;
    position: relative;
    color: #fff;
    z-index: 3;

    &:before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      color: #fff;
      z-index: -1;
      -webkit-text-stroke: 4px #17438d;
    }
  }
}

.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 9999 !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}

.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  width: px(819);
  min-height: px(552);
  padding-top: px(45);
  padding-bottom: px(60);
  // background: url(#{$cdnUrl}/images/popup.png) no-repeat center bottom;
  // background-size: 100% 100%;
  background-color: #fff;
  border: 0 !important;
  outline: none;
  z-index: 1;

  .modal-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  &.modal-select {
    width: px(860);
    min-height: px(471);

    &.special {
      width: px(1400);
      min-height: px(850);
    }
  }

  &.modal-gifts {
    width: px(732);
    min-height: px(765);
  }

  &.modal-received-gift {
    width: px(660);
    min-height: px(390);
  }

  &.modal-received-gifts {
    width: px(720);
    min-height: px(605);
    padding-bottom: px(40);

    .modal-description {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.modal-history {
    width: px(1120);
    min-height: px(805);
    padding-bottom: px(40);

    .modal-description {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.modal-mission {
    width: px(440);
    min-height: px(230);
  }

  .close {
    position: absolute;
    width: px(35);
    height: px(35);
    // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    // background-size: 100%;
    top: px(15);
    right: px(15);
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #fff !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
    transition: transform 0.25s ease;
  }

  h2 {
    font-size: px(26);
    margin-bottom: px(15);
    width: 100%;
    text-align: center;
    color: #1e2127;
    justify-content: center;
    line-height: 100%;
    padding: 0 0 px(20) 0;
    text-align: center;
    height: auto;
    text-transform: uppercase;
    // font-family: $font-utm;
    border-bottom: 2px solid #40dff6;
  }

  h3 {
    font-size: px(22);
  }

  p,
  li {
    font-size: px(20);
    color: #1e2127;
    line-height: 150%;
    font-weight: 500;
    margin-bottom: px(15);
  }
}
