/// Variables
/// @see https://www.smashingmagazine.com/2016/01/variables-in-css-architecture/

/// Grid
/// Using rem-units to scale for accessibility (based on the user's browser settings, default 1rem equals 16px)
/// @see layout/grid
/// grid class specified below ($breakpoints)

$grid-max-width:  120rem;
$grid-gutter:     1rem;
$grid-columns:    12;
$grid-show-outlines: false; //to show the grid's outlines
$img: '/';
// $img: '/';
@function px($number) {
  $result: 0;
  $result: $number/1920 * 100;
  @return $result+vw;
}
/// Breakpoints - used by the grid and media query manager
/// @type Map
/// @prop {size}: [min-width] - breakpoint name and minimum width for media queries
$breakpoints: (
  'xs': 0,
  'sm': 420px,
  'md': 720px,
  'lg': 960px,
  'xl': 1200px
);

/// mq() - media query manager
/// @see vendor/mq
/// @link https://github.com/sass-mq/sass-mq
$mq-breakpoints: $breakpoints;
$mq-base-font-size: 15px;
$mq-show-breakpoints: ();

/// Fonts
/// @TODO: choose color variable naming convention
$roboto: 'Roboto', sans-serif;
$Oswald:'Oswald',sans-serif;
$color-green:#009139;
$font-size: 1.5rem;
$font-primary: $roboto;

$golden: #cdbe91;
$golden-light: #f0e6d2;

/// Colors
$primary-color:#b99562;
$blue:  #18274e;
$blue-dark: #6396b1;
$blue-light: #2456c1;
$green: #51D66B;
$orange: #e8a84e;
$yellow: #f2da00;

$pink: #fe71a5;
$aqua: #1dc5e8;
$purple: #4e26ad;

$white:         #fff;
$gray-light:    #e5e5e5;
$gray:          #999;
$gray-dark:     #333;
$black:         #000;
$smoke: #7f868a;
$red: #f72616;

$color-default: $golden; //default text color

$color-primary:       $golden;
$color-primary-light: $golden-light;
$color-primary-dark:  $golden;

$color-secondary:       $green;
$color-secondary-light: lighten($green, 10%);
$color-secondary-dark:  darken($green, 10%);

$color-success: green;
$color-warning: orange;
$color-alert:   red;


/// Form
$form-height:   40px;


/// Dimensions - Widths and heights
$height-header: 100px;  //example!
$height-footer: 100px;  //example!
